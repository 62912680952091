/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers.
 */
body {
  margin: 0; }

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none; }

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none; }

/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none; }

:root {
  --color-black: #191a1b;
  --color-white: #f3f4f4;
  --color-loader: #e91e63;
  --color-button: #f952b9;
  --color-meta: #9e9e9e;
  --color-vote-gradient: linear-gradient(
    45deg,
    rgb(246, 46, 36),
    rgb(255, 133, 27),
    rgb(255, 221, 0),
    rgb(46, 204, 64),
    rgb(0, 116, 217),
    rgb(176, 13, 201)
  );
  --color-vote-submit-gradient: -webkit-linear-gradient(
    -45deg,
    #fff200 0%,
    #f15a24 100%
  );
  --color-button-gradient: -webkit-linear-gradient(
    -45deg,
    #f952b9 0%,
    #ff3052 100%
  ); }

@font-face {
  font-family: 'AvenirNext-Regular';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  src: url("../fonts/subset-AvenirNext-Regular.eot");
  src: url("../fonts/subset-AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-Regular.woff") format("woff"), url("../fonts/subset-AvenirNext-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'AvenirNextCondensed-Bold';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  src: url("../fonts/subset-AvenirNextCondensed-Bold.eot");
  src: local("Avenir Next Condensed Bold"), local("AvenirNextCondensed-Bold"), url("../fonts/subset-AvenirNextCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNextCondensed-Bold.woff") format("woff"), url("../fonts/subset-AvenirNextCondensed-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'PlayfairDisplay-Black';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/PlayfairDisplay-Black.eot");
  src: local("Playfair Display Black"), local("PlayfairDisplay-Black"), url("../fonts/PlayfairDisplay-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/PlayfairDisplay-Black.woff") format("woff"), url("../fonts/PlayfairDisplay-Black.ttf") format("truetype"); }

@font-face {
  font-family: 'thumbs-down';
  src: url("../fonts/thumbs-down.ttf?zfddyj") format("truetype"), url("../fonts/thumbs-down.woff?zfddyj") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'thumbs-up';
  src: url("../fonts/thumbs-up.ttf?wtiepu") format("truetype"), url("../fonts/thumbs-up.woff?wtiepu") format("woff");
  font-weight: normal;
  font-style: normal; }

.rxmg-body {
  background-color: var(--color-black);
  display: flex;
  align-items: center;
  min-height: 85vh;
  justify-content: center;
  overflow-x: hidden; }

.rxmg-card {
  display: block;
  width: 220px; }

.rxmg-thank-you-float-right {
  float: right; }

.rxmg-thank-you-float-left {
  float: left;
  text-align: right; }

.rxmg-thank-you-jar {
  display: flex;
  align-items: center; }

.rxmg-thank-you-msg {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-white);
  font-size: 14px; }

.rxmg-client-logo {
  display: block;
  margin: 0 auto; }

.rxmg-zone-name {
  display: block;
  color: var(--color-white);
  font-family: AvenirNext-Regular, Arial, sans-serif;
  width: 150px;
  font-size: 14px;
  margin: 14px auto;
  word-wrap: break-word;
  text-align: center; }

.rxmg-track-component {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.rxmg-track-name {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-white);
  border: solid 2px var(--color-white);
  font-size: 14px;
  min-height: 16px;
  padding: 8px 6px;
  width: 120px;
  text-align: left; }

.rxmg-track-label {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-meta);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  flex: 0 0 auto;
  align-self: flex-end;
  margin-bottom: 6px;
  margin-left: 4px; }

.rxmg-artist-component {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.rxmg-artist-name {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-white);
  border: solid 2px var(--color-white);
  font-size: 14px;
  min-height: 16px;
  padding: 8px 6px;
  width: 120px;
  text-align: right; }

.rxmg-artist-label {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-meta);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  flex: 0 0 auto;
  align-self: flex-start;
  margin-top: 6px;
  margin-right: 4px; }

.rxmg-social-media {
  display: block;
  text-align: center;
  margin: 15px 0 5px; }

.rxmg-meta-info {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  font-size: 13px;
  text-align: center;
  color: var(--color-white); }

.rxmg-album {
  height: 130px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  width: 130px;
  position: absolute;
  border: 2px solid var(--color-white); }

.rxmg-album-hidden {
  opacity: 0; }

.rxmg-album-component {
  display: flex;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  width: 130px;
  justify-content: center;
  align-items: center; }

.rxmg-thumbs-jar {
  display: flex;
  margin: 14px 0 0 0;
  justify-content: center;
  position: relative; }

.rxmg-error-jar-center {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center; }

.rxmg-error-header-black {
  font-family: PlayfairDisplay-Black, Arial, sans-serif;
  color: var(--color-black);
  font-size: 28px;
  text-align: center; }

.rxmg-error-header-white {
  font-family: PlayfairDisplay-Black, Arial, sans-serif;
  color: var(--color-white);
  font-size: 28px;
  text-align: center; }

.rxmg-error-text-white {
  font-family: AvenirNext-Regular, Arial, sans-serif;
  color: var(--color-white);
  font-size: 20px;
  text-align: center; }

.rxmg-frame-header-white {
  display: inline-flex;
  padding: 15px 27px;
  align-items: center;
  justify-content: center;
  border: 6px solid var(--color-white);
  position: relative;
  background-color: var(--color-white);
  color: var(--color-black); }

.rxmg-frame-header-white:before {
  content: ' ';
  position: absolute;
  z-index: 0;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 4px solid var(--color-black); }

.rxmg-frame-body-white {
  border: 2px solid var(--color-white);
  padding: 35px 50px;
  color: var(--color-white); }

.rxmg-frame-body-position {
  margin-left: 40px;
  margin-top: -2px;
  margin-right: 40px; }

.rxmg-button {
  font-family: AvenirNextCondensed-Bold, Arial, sans-serif;
  background: var(--color-button);
  background: var(--color-button-gradient);
  padding: 13px 16px;
  margin-left: auto;
  cursor: pointer;
  font-size: 21px;
  background-size: 150%;
  background-position: 0% 50%;
  transition: all 0.35s ease; }

.rxmg-button:focus,
.rxmg-button:hover {
  color: var(--color-black);
  background-position: -200% 50%; }

.sb-default {
  margin: 0.5em; }

.sb-default .sb-wrapper {
  --button-color: var(
    --color-black
  ) !important;
  font-size: 18px !important;
  color: var(--color-black);
  cursor: pointer; }
  .sb-default .sb-wrapper:focus {
    outline: 0; }

.sb-default .sb-wrapper svg {
  color: var(--color-white); }

.rxmg-thumbs-div {
  position: relative;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0 10px;
  cursor: pointer; }

.rxmg-thumbs-div div[data-name='mojs-shape'] {
  pointer-events: none; }

.rxmg-thumbs-up {
  font-family: 'thumbs-up' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 40px;
  display: block;
  cursor: pointer;
  margin: 0 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: var(--color-white);
  border: solid 2px var(--color-white);
  background: var(--color-vote-gradient);
  background-size: 600%;
  animation: gradient-anim 16s infinite;
  position: relative;
  z-index: 1000 !important; }

.rxmg-thumbs-up:before {
  content: '\e900';
  position: relative;
  top: 8px;
  left: 9px; }

.rxmg-voted {
  pointer-events: none; }

.rxmg-voted.rxmg-thumbs {
  background: var(--color-vote-submit-gradient); }

.rxmg-thumbs-down {
  font-family: 'thumbs-down' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 40px;
  display: block;
  cursor: pointer;
  margin: 0 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: var(--color-white);
  border: solid 2px var(--color-white);
  background: var(--color-vote-gradient);
  background-size: 600%;
  animation: gradient-anim 16s infinite;
  position: relative;
  z-index: 1000 !important;
  animation-delay: -8s; }

.rxmg-thumbs-down:before {
  content: '\e900';
  position: relative;
  top: 12px;
  left: 9px; }

.rxmg-show-thumbs {
  opacity: 1;
  height: unset;
  width: unset; }

.rxmg-thumbs-cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0 !important;
  pointer-events: none;
  text-align: center; }
  .rxmg-thumbs-cover__wrapper {
    position: relative; }

.rxmg-thumbs-cover:after {
  content: '';
  display: inline-flex;
  border: 2px solid var(--color-black);
  border-left-color: var(--color-loader);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: baseline;
  animation: donut-spin 0.7s linear infinite; }

@keyframes donut-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes gradient-anim {
  0% {
    background-position: 0; }
  50% {
    background-position: 100%; }
  100% {
    background-position: 0; } }

.rxmg-vote-kiosk {
  margin-bottom: 20px; }

.rxmg-thank-you-msg-countdown {
  color: var(--color-meta); }
