@import 'reset';

:root {
  --color-black: #191a1b;
  --color-white: #f3f4f4;
  --color-loader: #e91e63;
  --color-button: #f952b9;
  --color-meta: #9e9e9e;
  --color-vote-gradient: linear-gradient(
    45deg,
    rgb(246, 46, 36),
    rgb(255, 133, 27),
    rgb(255, 221, 0),
    rgb(46, 204, 64),
    rgb(0, 116, 217),
    rgb(176, 13, 201)
  );
  --color-vote-submit-gradient: -webkit-linear-gradient(
    -45deg,
    #fff200 0%,
    #f15a24 100%
  );
  --color-button-gradient: -webkit-linear-gradient(
    -45deg,
    #f952b9 0%,
    #ff3052 100%
  );
}

@font-face {
  font-family: 'AvenirNext-Regular';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  src: url('../fonts/subset-AvenirNext-Regular.eot');
  src: url('../fonts/subset-AvenirNext-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/subset-AvenirNext-Regular.woff') format('woff'),
    url('../fonts/subset-AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirNextCondensed-Bold';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  src: url('../fonts/subset-AvenirNextCondensed-Bold.eot');
  src: local('Avenir Next Condensed Bold'), local('AvenirNextCondensed-Bold'),
    url('../fonts/subset-AvenirNextCondensed-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/subset-AvenirNextCondensed-Bold.woff') format('woff'),
    url('../fonts/subset-AvenirNextCondensed-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Black';
  font-display: block;
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/PlayfairDisplay-Black.eot');
  src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
    url('../fonts/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PlayfairDisplay-Black.woff') format('woff'),
    url('../fonts/PlayfairDisplay-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'thumbs-down';
  src: url('../fonts/thumbs-down.ttf?zfddyj') format('truetype'),
    url('../fonts/thumbs-down.woff?zfddyj') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'thumbs-up';
  src: url('../fonts/thumbs-up.ttf?wtiepu') format('truetype'),
    url('../fonts/thumbs-up.woff?wtiepu') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin font-playfair {
  font-family: PlayfairDisplay-Black, Arial, sans-serif;
}

@mixin font-avenir {
  font-family: AvenirNext-Regular, Arial, sans-serif;
}

@mixin font-avenir-condensed {
  font-family: AvenirNextCondensed-Bold, Arial, sans-serif;
}

@mixin thumbs {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $vote-size;
  display: block;
  cursor: pointer;
  margin: 0 10px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 60px;
  height: 60px;
  border-radius: 30px;

  color: var(--color-white);
  border: solid 2px var(--color-white);
  background: var(--color-vote-gradient);
  background-size: 600%;
  animation: gradient-anim 16s infinite;

  position: relative;
  z-index: 1000 !important;
}

@mixin track-info-text {
  @include font-avenir;
  color: var(--color-white);
  border: solid 2px var(--color-white);
  font-size: 14px;
  min-height: 16px;
  padding: 8px 6px;
  width: 120px;
}

@mixin track-info-label {
  @include font-avenir;
  color: var(--color-meta);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  flex: 0 0 auto;
}

.rxmg-body {
  background-color: var(--color-black);
  display: flex;
  align-items: center;
  min-height: 85vh;
  justify-content: center;
  overflow-x: hidden;
}

.rxmg-card {
  display: block;
  width: 220px;
}

.rxmg-thank-you-float-right {
  float: right;
}

.rxmg-thank-you-float-left {
  float: left;
  text-align: right;
}

.rxmg-thank-you-jar {
  display: flex;
  align-items: center;
}

.rxmg-thank-you-msg {
  @include font-avenir;
  color: var(--color-white);
  font-size: 14px;
}

.rxmg-client-logo {
  display: block;
  margin: 0 auto;
}

.rxmg-zone-name {
  display: block;
  color: var(--color-white);
  @include font-avenir;
  width: 150px;
  font-size: 14px;
  margin: 14px auto;
  word-wrap: break-word;
  text-align: center;
}

.rxmg-track-component {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rxmg-track-name {
  @include track-info-text();
  text-align: left;
}

.rxmg-track-label {
  @include track-info-label();
  align-self: flex-end;
  margin-bottom: 6px;
  margin-left: 4px;
}

.rxmg-artist-component {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rxmg-artist-name {
  @include track-info-text();
  text-align: right;
}

.rxmg-artist-label {
  @include track-info-label();
  align-self: flex-start;
  margin-top: 6px;
  margin-right: 4px;
}

.rxmg-social-media {
  display: block;
  text-align: center;
  margin: 15px 0 5px;
}

.rxmg-meta-info {
  @include font-avenir;
  font-size: 13px;
  text-align: center;
  color: var(--color-white);
}

$album-size: 130px;

.rxmg-album {
  height: $album-size;
  opacity: 1;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
  width: $album-size;
  position: absolute;
  border: 2px solid var(--color-white);
}

.rxmg-album-hidden {
  opacity: 0;
}

.rxmg-album-component {
  display: flex;
  height: $album-size;
  margin-left: auto;
  margin-right: auto;
  width: $album-size;
  justify-content: center;
  align-items: center;
}

$vote-size: 40px;

.rxmg-thumbs-jar {
  display: flex;
  margin: 14px 0 0 0;
  justify-content: center;
  position: relative;
}

.rxmg-error-jar-center {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.rxmg-error-header-black {
  @include font-playfair;
  color: var(--color-black);
  font-size: 28px;
  text-align: center;
}

.rxmg-error-header-white {
  @include font-playfair;
  color: var(--color-white);
  font-size: 28px;
  text-align: center;
}

.rxmg-error-text-white {
  @include font-avenir;
  color: var(--color-white);
  font-size: 20px;
  text-align: center;
}

.rxmg-frame-header-white {
  display: inline-flex;
  padding: 15px 27px;
  align-items: center;
  justify-content: center;
  border: 6px solid var(--color-white);
  position: relative;
  background-color: var(--color-white);
  color: var(--color-black);
}

.rxmg-frame-header-white:before {
  content: ' ';
  position: absolute;
  z-index: 0;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 4px solid var(--color-black);
}

.rxmg-frame-body-white {
  border: 2px solid var(--color-white);
  padding: 35px 50px;
  color: var(--color-white);
}

.rxmg-frame-body-position {
  margin-left: 40px;
  margin-top: -2px;
  margin-right: 40px;
}

.rxmg-button {
  @include font-avenir-condensed;
  background: var(--color-button);
  background: var(--color-button-gradient);
  padding: 13px 16px;
  margin-left: auto;
  cursor: pointer;
  font-size: 21px;
  background-size: 150%;
  background-position: 0% 50%;
  transition: all 0.35s ease;
}

.rxmg-button:focus,
.rxmg-button:hover {
  color: var(--color-black);
  background-position: -200% 50%;
}

// overwrite twitter share icon
.sb-default {
  margin: 0.5em;
}

.sb-default .sb-wrapper {
  --button-color: var(
    --color-black
  ) !important; // twitter background button color
  font-size: 18px !important;
  color: var(--color-black); // twitter bird color
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.sb-default .sb-wrapper svg {
  color: var(--color-white);
}

.rxmg-thumbs-div {
  position: relative;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0 10px;
  cursor: pointer;
}

.rxmg-thumbs-div div[data-name='mojs-shape'] {
  pointer-events: none;
}

.rxmg-thumbs-up {
  font-family: 'thumbs-up' !important;
  @include thumbs();
}

.rxmg-thumbs-up:before {
  content: '\e900';
  position: relative;
  top: 8px;
  left: 9px;
}

.rxmg-voted {
  pointer-events: none;
}

.rxmg-voted.rxmg-thumbs {
  background: var(--color-vote-submit-gradient);
}

.rxmg-thumbs-down {
  font-family: 'thumbs-down' !important;
  @include thumbs();
  animation-delay: -8s;
}

.rxmg-thumbs-down:before {
  content: '\e900';
  position: relative;
  top: 12px;
  left: 9px;
}

.rxmg-show-thumbs {
  opacity: 1;
  height: unset;
  width: unset;
}

.rxmg-thumbs-cover {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0 !important;
  pointer-events: none;
  text-align: center;

  &__wrapper {
    position: relative;
  }
}

.rxmg-thumbs-cover:after {
  content: '';
  display: inline-flex;
  border: 2px solid var(--color-black);
  border-left-color: var(--color-loader);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: baseline;
  animation: donut-spin 0.7s linear infinite;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient-anim {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0;
  }
}

.rxmg-vote-kiosk {
  margin-bottom: 20px;
}

.rxmg-thank-you-msg-countdown {
  color: var(--color-meta);
}
